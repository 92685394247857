import React, { ReactNode } from "react";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { LoaderState } from "reducers/loader";

export interface LoaderComponentInterface {
  children: ReactNode;
}

const LoaderComponent: React.FC<LoaderComponentInterface> = ({ children }) => {
  const loaderState: LoaderState = useSelector(
    (state: any) => state.loaderReducer
  );

  return (
    <Spin size="large" spinning={loaderState.isMainLoading}>
      {children}
    </Spin>
  );
};

export default LoaderComponent;
