import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import UserProfileComponent from "components/userProfile";
import { MenuState, MenuAction, MenuActionKind } from "reducers/menu";

const Header = () => {
  const menuState: MenuState = useSelector((state: any) => state.menuReducer);
  const dispatch = useDispatch();

  return (
    <div className="h-20 bg-sidebar fixed top-0 right-0 left-0 z-50">
      <div className="flex items-center h-full justify-between px-5">
        <div className="flex items-center">
          <div
            className="hideOnDesktop cursor-pointer"
            onClick={() => {
              const action: MenuAction = {
                type: menuState.collapsed
                  ? MenuActionKind.COLLAPSED_OFF
                  : MenuActionKind.COLLAPSED_ON,
              };
              dispatch(action);
            }}
          >
            {menuState.collapsed && (
              <MenuUnfoldOutlined className="text-white text-xl mr-4" />
            )}
            {!menuState.collapsed && (
              <MenuFoldOutlined className="text-white text-xl mr-4" />
            )}
          </div>
          <span className="font-semibold text-2xl text-white">StreamerDap</span>
        </div>
        <div className="flex items-center justify-items-end">
          <div>
            <UserProfileComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
