import CampaignManage from "../campaigns/manage";

const CampaignRequestManage = () => {

  return (
    <CampaignManage/>
  );
};

export default CampaignRequestManage;
