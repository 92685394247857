import { App, Popconfirm } from "antd";
import { apiMethod } from "api/transaction";
import TableComponent from "components/table";
import PageContainer from "layouts/components/pageContainer";
import { Fragment, useState } from "react";
import { formattedDateTime } from "utils/dateFormat";
import { BrandTransactionStatus } from "utils/enum";
import { CheckOutlined,CheckCircleOutlined } from "@ant-design/icons";

const TransactionHistory = () => {
  const { message } = App.useApp();
  const [reloadTable, setreloadTable] = useState(0);
  const updatePaymentStatus = async (
    id: number,
    status: number,
    brandId: number,
    price: number
  ) => {
    apiMethod
      .updatePaymentStatus(id, status, brandId, price)
      .then((res: any) => {
        message.success("Payment status updated successfully.");
        setreloadTable((reloadTable) => reloadTable + 1);
      })
      .catch((err: any) => {});
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      sorter: true,
      dataType: "text",
      render: (value: string) => {
        return <span> {formattedDateTime(value)}</span>;
      },
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Brand Name",
      dataIndex: "brandEntityName",
      sorter: false,
      dataType: "text",
    },
    {
      title: "Daps",
      dataIndex: "daps",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value: any, row: any, index: number) => {
        return (
          <Fragment>
            {value && value === BrandTransactionStatus.Unpaid ? (
              <Popconfirm
                placement="bottom"
                title={"Update the Payment status?"}
                description={"Are you sure to update the payment status to 'Paid'?"}
                ok-text="Yes"
                cancel-text="No"
                onConfirm={() =>
                  updatePaymentStatus(
                    row.id,
                    BrandTransactionStatus.Paid,
                    row.brandId,
                    row.amount,
                  )
                }
                icon={ <CheckCircleOutlined style={{ color: "#237804" }} />}
              >
                <CheckOutlined style={{ color: "#6cb557" }} title="Unpaid" />
              </Popconfirm>
            ) : (
              <span className="text-verified">Paid</span>
            )}
          </Fragment>
        );
      },
    },
  ];

  return (
    <PageContainer title={"Transaction History"}>
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        columns={columns}
        reloadTable={reloadTable}
        otherGetDataFunc={(obj:any)=>{
          obj["type"] = 1;
          return obj;
        }}
        customAction={true}
      />
    </PageContainer>
  );
};

export default TransactionHistory;
