import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Row, Col } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/brands";
import { routes } from "routes";
import TableComponent from "components/table";
import { BrandEntityType } from "utils/enum";

const BrandManage = () => {
  const [data, setData] = useState<any>({});
  const { id } = useParams();
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        title={id && data ? data["name"] : "Create Brand"}
        subTitle="Brand"
      />
      <FormComponent
        routes={routes.brands}
        apiMethod={apiMethod}
        id={id}
        setData={setData}
        form={form}
        canSave={false}
        canNew={false}
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item name="name" label={"Name"}>
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="email" label="Email">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="phoneNo" label="Phone No">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="brandEntityTypeName" label="Role">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item
                name="brandEntityName"
                label={
                  data.brandEntityTypeId === BrandEntityType.Brand
                    ? "Company Name"
                    : "Agency Name"
                }
              >
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="address" label="Company Address">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            {data.brandEntityTypeId === BrandEntityType.Agency && (
              <Col span={24} sm={12}>
                <Form.Item name="agencyTypeName" label="Agency Type">
                  <Input autoComplete="off" disabled />
                </Form.Item>
              </Col>
            )}
            <Col span={24} sm={12}>
              <Form.Item name="position" label="Position">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            {data.brandEntityTypeId === BrandEntityType.Agency && (
              <Col span={24} sm={12}>
                <Form.Item
                  name="noOfBrandsHandled"
                  label="No Of Brands Handled"
                >
                  <Input autoComplete="off" disabled />
                </Form.Item>
              </Col>
            )}
            <Col span={24} sm={12}>
              <Form.Item
                name="streamingExperienceName"
                label="Streaming Experience"
              >
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
          </Row>
          <label>Category</label>
          {data.category && (
            <TableComponent
              apiMethod={apiMethod}
              apiKeyNames={{ list: "list" }}
              data={{ data: data.category }}
              isPagination={false}
              columns={[
                {
                  title: "Title",
                  dataIndex: "title",
                  sorter: false,
                  dataType: "text",
                },
                {
                  title: "Image",
                  dataIndex: "image",
                  sorter: false,
                  dataType: "image",
                },
              ]}
            />
          )}
        </div>
      </FormComponent>
    </>
  );
};

export default BrandManage;
