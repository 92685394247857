import axiosInstance from "utils/axiosInterceptor";

export function list(data: any) {
  return axiosInstance({
    url: "/admin/creator/list?" + data,
    method: "get",
  });
}

export function getById(id: string) {
  return axiosInstance({
    url: "/admin/creator/getByIdAdmin?id=" + id,
    method: "get",
  });
}

export function updateVerifiedByAdminById(id: any, verifiedByAdmin: any) {
  return axiosInstance({
    url: "/creator/updateVerifiedByAdminById/" + id,
    method: "post",
    data: { verifiedByAdmin },
  });
}

export function updateCcvToAmountById(id: any, ccvToAmount: any) {
  return axiosInstance({
    url: "/admin/creator/updateCcvToAmountById/" + id,
    method: "put",
    data: { ccvToAmount },
  });
}

export const apiMethod = { list, getById, updateVerifiedByAdminById ,updateCcvToAmountById};
