import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RoutesObjInterface } from "routes";

export interface PageHeaderInterface {
  title: string;
  subTitle?: string;
  route?: {[key: string]: RoutesObjInterface};
}

const PageHeader: React.FC<PageHeaderInterface> = ({
  title,
  subTitle,
  route,
}) => {
  const navigate = useNavigate();

  return (
    <div className="shadow-sm">
      <div className="w-full px-5 py-3">
        <div className="flex justify-between">
          <div>
            <div className="text-2xl text-paracolor font-semibold">{title}</div>
            <div className="text-xs text-gray-400 uppercase tracking-wider font-semibold">
              {subTitle}
            </div>
          </div>
          <div className="space-x-2">
            {Object.keys(route ?? {}).map((e,i)=> <Button key={i}
                type="primary"
                onClick={() => {
                  navigate(route![e].path);
                }}
              >
                {e}
              </Button>)}
          {/* <Button
                type="primary"
                onClick={() => {
                  // navigate(createRoute.path);
                }}
              >
                Create
              </Button>
            {createRoute && (
              <Button
                type="primary"
                onClick={() => {
                  navigate(createRoute.path);
                }}
              >
                Create
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
