import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/dashboard";
import { useEffect, useState } from "react";
import { MdCategory,MdOutlineFeedback,MdOutlineCampaign } from "react-icons/md";
import { SiBrandfolder } from "react-icons/si";
import { AiOutlineCopyrightCircle } from "react-icons/ai";

const Dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    apiMethod
      .dashboard()
      .then((res: any) => {
        const result = res.data.result;
        setData([
          {
            title: "Brands",
            icon: <SiBrandfolder className="text-4xl text-white" />,
            count: result.brandsCount ?? 0,
            route: routes.brands,
          },
          {
            title: "Creators",
            icon: <AiOutlineCopyrightCircle className="text-4xl text-white" />,
            count: result.creatorsCount ?? 0,
            route: routes.creators,
          },
          {
            title: "Campaigns",
            icon: <MdOutlineCampaign className="text-4xl text-white" />,
            count: result.campaignsCount ?? 0,
            route: routes.campaigns,
          },
          {
            title: "Categories",
            icon: <MdCategory className="text-4xl text-white" />,
            count: result.categoriesCount ?? 0,
            route: routes.categories,
          },
          // {
          //   title: "Companies",
          //   icon: <HiBuildingOffice2 className="text-4xl text-white" />,
          //   count: result.companiesCount ?? 0,
          //   route: routes.companies,
          // },
          // {
          //   title: "Agencies",
          //   icon: <HiMiniBuildingOffice className="text-4xl text-white" />,
          //   count: result.agenciesCount ?? 0,
          //   route: routes.agencies,
          // },
          // {
          //   title: "Agency Types",
          //   icon: <HiMiniBuildingOffice className="text-4xl text-white" />,
          //   count: result.agencyTypesCount ?? 0,
          //   route: routes.agencyTypes,
          // },
          {
            title: "FeedBacks",
            icon: <MdOutlineFeedback className="text-4xl text-white" />,
            count: result.feedbacksCount ?? 0,
            route: routes.feedBacks,
          },
        ]);
      })
      .catch((err: any) => {
      });
  }, []);

  return (
    <PageContainer title="Dashboard">
      <Row gutter={[16, 16]}>
        {data.map((e, i) => (
          <Col
            key={i}
            className="gutter-row categorytable"
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={6}
          >
            <div
              className="bg-card px-5 py-5 rounded-2xl flex flex-row items-center cursor-pointer"
              onClick={() => {
                navigate(e.route.list.path);
              }}
            >
              <div className="bg-primary items-center	justify-center w-16 h-16 rounded-full flex">
                {e.icon}
              </div>
              <div className="ml-4">
                <div className="text-4xl text-white font-semibold">{e.count}</div>
                <div className="text-lg text-paracolor">{e.title}</div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </PageContainer>
  );
};

export default Dashboard;
