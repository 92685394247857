import axiosInstance from "utils/axiosInterceptor";

export function list(data: any) {
  return axiosInstance({
    url: "/admin/brand/list?" + data,
    method: "get",
  });
}

export function getById(id: string) {
  return axiosInstance({
    url: "/admin/brand/getByIdAdmin/" + id,
    method: "get",
  });
}

export function updateVerifiedByAdminById(id: any, verifiedByAdmin: any) {
  return axiosInstance({
    url: "/brand/updateVerifiedByAdminById/" + id,
    method: "post",
    data: { verifiedByAdmin },
  });
}

export function updatePaymentTermById(
  id: any,
  paymentTermStatus: any,
  paymentTerm: any
) {
  return axiosInstance({
    url: "/brand/updatePaymentTermById/" + id,
    method: "post",
    data: { paymentTermStatus, paymentTerm },
  });
}

export function updateCPMById(id: any, cpm: any) {
  return axiosInstance({
    url: "/admin/brand/updateCPMById/" + id,
    method: "put",
    data: { cpm },
  });
}

export const apiMethod = {
  list,
  getById,
  updateVerifiedByAdminById,
  updatePaymentTermById,
  updateCPMById,
};
