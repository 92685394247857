import axiosInstance from "utils/axiosInterceptor";

export function updateById(data: any) {
  return axiosInstance({
    url: "/admin/config/update",
    method: "put",
    data: data,
  });
}

export function getById(id: string) {
  return axiosInstance({
    url: "/config",
    method: "get",
  });
}

export const apiMethod = { updateById, getById };
