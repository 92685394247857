import axiosInstance from "utils/axiosInterceptor";

export function list(data: any) {
  return axiosInstance({
    url: "/category/list?" + data,
    method: "get",
  });
}

export function create(data: any) {
  var formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return axiosInstance({
    url: "/category/create",
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function updateById(data: any) {
  var formData = new FormData();
  for (var key in data) {
    formData.append(key, data[key]);
  }
  return axiosInstance({
    url: "/category/updateById/" + data.id,
    method: "put",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getById(id: string) {
  return axiosInstance({
    url: "/category/getById/" + id,
    method: "get",
  });
}

export function deleteById(id: string) {
  return axiosInstance({
    url: "/category/deleteById/" + id,
    method: "delete",
  });
}


export function getPrimaryCategoryApi() {
  return axiosInstance({
    url: "primaryCategory/list",
    method: "get",
  });
}

export const apiMethod = { list, create, updateById, getById, deleteById, getPrimaryCategoryApi };
