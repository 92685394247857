import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Row, Col } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/creators";
import { routes } from "routes";
import TableComponent from "components/table";
import { CreatorEntityType } from "utils/enum";

const CreatorManage = () => {
  const [data, setData] = useState<any>({});
  const { id } = useParams();
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        title={id && data ? data["agentFullName"] : "Create Creator"}
        subTitle="Creator"
      />
      <FormComponent
        routes={routes.creators}
        apiMethod={apiMethod}
        id={id}
        setData={setData}
        form={form}
        canSave={false}
        canNew={false}
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item name="agentFullName" label={"Name"}>
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="email" label="Email">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="phoneNo" label="Phone No">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="creatorEntityTypeName" label="Role">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            {data.creatorEntityTypeId === CreatorEntityType.Agency && (
              <Col span={24} sm={12}>
                <Form.Item name="roleName" label="Role Type">
                  <Input autoComplete="off" disabled />
                </Form.Item>
              </Col>
            )}
            <Col span={24} sm={12}>
              <Form.Item name="address" label="Address">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item
                name="streamingPlatformTypeName"
                label="Streaming Platform Type"
              >
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="streamerHandle" label="Streamer Handle">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="companyName" label="Company Name">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            {data.creatorEntityTypeId === CreatorEntityType.Agency && (
              <Col span={24} sm={12}>
                <Form.Item
                  name="numberOfStreamerHandle"
                  label="Number Of Streamer Handle"
                >
                  <Input autoComplete="off" disabled />
                </Form.Item>
              </Col>
            )}
            <Col span={24} sm={12}>
              <Form.Item name="discordId" label="Discord Id">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item
                name="howLongHaveBeenStreaming"
                label="How Long Have Been Streaming"
              >
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
          </Row>
          <label>Category</label>
          {data.category && (
            <TableComponent
              apiMethod={apiMethod}
              apiKeyNames={{ list: "list" }}
              data={{ data: data.category }}
              isPagination={false}
              columns={[
                {
                  title: "Title",
                  dataIndex: "title",
                  sorter: false,
                  dataType: "text",
                },
                {
                  title: "Image",
                  dataIndex: "image",
                  sorter: false,
                  dataType: "image",
                },
              ]}
            />
          )}
        </div>
      </FormComponent>
    </>
  );
};

export default CreatorManage;
