import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Row, Col } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/agencyTypes";
import { routes } from "routes";

const AgencyTypeManage = () => {
  const [data, setData] = useState<any>({});
  const { id } = useParams();
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        title={id && data ? data["name"] : "Create Agency Type"}
        subTitle="Agency Type"
      />
      <FormComponent
        routes={routes.agencyTypes}
        apiMethod={apiMethod}
        id={id}
        setData={setData}
        form={form}
        canSave={true}
        canNew={true}
        deleteMsgTitle="Delete the Agency Type"
        deleteMsgDescription="Are you sure to delete this Agency Type?"
        
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    whitespace:true,
                    message: "Name is required",
                  },
                  {
                    max: 30,
                    message: "Name cannot exceed 30 characters",
                  },
                ]}
              >
                <Input autoComplete="off" maxLength={30}/>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </FormComponent>
    </>
  );
};

export default AgencyTypeManage;
