import { ReactNode } from "react";
import Login from "views/login";
import Errors from "views/errors";
import Dashboard from "views/dashboard";
import Categories from "views/categories";
import CategoryManage from "views/categories/manage";
import Companies from "views/companies";
import CompanyManage from "views/companies/manage";
import Agencies from "views/agencies";
import AgencyManage from "views/agencies/manage";
import Brands from "views/brands";
import BrandManage from "views/brands/manage";
import AgencyTypes from "views/agencyTypes";
import AgencyTypeManage from "views/agencyTypes/manage";
import Creators from "views/creators";
import CreatorManage from "views/creators/manage";
import Settings from "../views/settings";
import BrandSetting from "../views/brands/setting";
import CreatorSetting from "../views/creators/setting";
import FeedBacks from "../views/feedBacks";
import FeedBackManage from "../views/feedBacks/manage";
import Campaigns from "../views/campaigns";
import CampaignManage from "../views/campaigns/manage";
import CampaignRequests from "../views/campaignRequests";
import CampaignRequestManage from "../views/campaignRequests/manage";
import TransactionHistory from "views/transactionHistory";
import Ratings from "../views/ratings";
import RatingManage from '../views/ratings/manage';

export interface RoutesInterface {
  list?: RoutesObjInterface;
  create?: RoutesObjInterface;
  edit?: RoutesObjInterface;
  view?: RoutesObjInterface;
  setting?: RoutesObjInterface;
}

export interface RoutesObjInterface {
  path: string;
  element: ReactNode;
}

export const otherRoutes: { [key: string]: RoutesObjInterface } = {
  login: { path: "/login", element: <Login /> },
  error: { path: "/*", element: <Errors /> },
};

export const routes: { [key: string]: RoutesInterface } = {
  dashboard: {
    list: { path: "/", element: <Dashboard /> },
  },
  brands: {
    list: {
      path: "/brands",
      element: <Brands />,
    },
    view: {
      path: "/brand/view/:id",
      element: <BrandManage />,
    },
    setting: {
      path: "/brand/setting/:id",
      element: <BrandSetting />,
    },
  },
  creators: {
    list: {
      path: "/creators",
      element: <Creators />,
    },
    view: {
      path: "/creator/view/:id",
      element: <CreatorManage />,
    },
    setting: {
      path: "/creator/setting/:id",
      element: <CreatorSetting />,
    },
  },
  campaigns: {
    list: {
      path: "/campaigns",
      element: <Campaigns />,
    },
    view: {
      path: "/campaign/view/:id",
      element: <CampaignManage />,
    },
  },
  campaignRequests: {
    list: {
      path: "/campaignRequests",
      element: <CampaignRequests />,
    },
    view: {
      path: "/campaignRequest/view/:id",
      element: <CampaignRequestManage />,
    },
  },
  brandCategories: {
    list: {
      path: "/brand/categories",
      element: <Categories type="Brand" />,
    },
    create: {
      path: "/brand/category/create",
      element: <CategoryManage type="Brand" />,
    },
    edit: {
      path: "/brand/category/edit/:id",
      element: <CategoryManage type="Brand" />,
    },
  },
  creatorCategories: {
    list: {
      path: "/creator/categories",
      element: (
        <div>
          <Categories type="Creator" />
        </div>
      ),
    },
    create: {
      path: "/creator/category/create",
      element: <CategoryManage type="Creator" />,
    },
    edit: {
      path: "/creator/category/edit/:id",
      element: <CategoryManage type="Creator" />,
    },
  },
  companies: {
    list: {
      path: "/companies",
      element: <Companies />,
    },
    create: {
      path: "/company/create",
      element: <CompanyManage />,
    },
    edit: {
      path: "/company/edit/:id",
      element: <CompanyManage />,
    },
  },
  agencies: {
    list: {
      path: "/agencies",
      element: <Agencies />,
    },
    create: {
      path: "/agency/create",
      element: <AgencyManage />,
    },
    edit: {
      path: "/agency/edit/:id",
      element: <AgencyManage />,
    },
  },
  agencyTypes: {
    list: {
      path: "/agencyTypes",
      element: <AgencyTypes />,
    },
    create: {
      path: "/agencyType/create",
      element: <AgencyTypeManage />,
    },
    edit: {
      path: "/agencyType/edit/:id",
      element: <AgencyTypeManage />,
    },
  },
  feedBacks: {
    list: {
      path: "/feedbacks",
      element: <FeedBacks />,
    },
    view: {
      path: "/feedback/view/:id",
      element: <FeedBackManage />,
    },
  },
  ratings: {
    list: {
      path: "/ratings",
      element: <Ratings />,
    },
    view: {
      path: "/rating/view/:id",
      element: <RatingManage />,
    },
  },
  settings: {
    list: {
      path: "/settings",
      element: <Settings />,
    },
  },
  transactionHistory: {
    list: {
      path: "/transactionHistory",
      element: <TransactionHistory />,
    },
  },
};
