import { Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import React from "react";

export interface SaveActionButtonInterface {
  onClick?: () => void;
}

const SaveActionButton: React.FC<SaveActionButtonInterface> = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick} >
      <SaveOutlined className="text-white-500" />
      Save
    </Button>
  );
};

export default SaveActionButton;
