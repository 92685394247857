import Campaigns from "../campaigns";

const CampaignRequests = () => {

  return (
    <Campaigns/>
  );
};

export default CampaignRequests;
