export enum LoaderActionKind {
  MAIN_LOADER_START = "MAIN_LOADER_START",
  MAIN_LOADER_STOP = "MAIN_LOADER_STOP",
}

export interface LoaderAction {
  type: LoaderActionKind;
}

export interface LoaderState {
  isMainLoading: boolean;
}

const initialState: LoaderState = {
  isMainLoading: false,
};

const loaderReducer = (
  state: LoaderState = initialState,
  action: LoaderAction
) => {
  switch (action.type) {
    case LoaderActionKind.MAIN_LOADER_START:
      return { ...state, isMainLoading: true };
    case LoaderActionKind.MAIN_LOADER_STOP:
      return { ...state, isMainLoading: false };
    default:
      return state;
  }
};

export default loaderReducer;
