import axiosInstance from "utils/axiosInterceptor";

export function login(data: any) {
  return axiosInstance({
    url: "/admin/login",
    method: "post",
    data,
  });
}

export function refreshToken(data: any) {
  return axiosInstance({
    url: "/refreshToken",
    method: "post",
    data,
  });
}

export function logout() {
  return axiosInstance({
    url: "/User/logout",
    method: "post",
  });
}
