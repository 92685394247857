import axiosInstance from "utils/axiosInterceptor";

export function dashboard() {
  return axiosInstance({
    url: "/admin/dashboard",
    method: "get",
  });
}

export const apiMethod = { dashboard };
