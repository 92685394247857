import { Form, Input, Button, App } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "api/auth";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

const Login = () => {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const onFinish = async ({email,password}: { email: string; password: string }) => {
    const trimmedEmail = email.trim();
    login({ email: trimmedEmail, password })
      .then((res: any) => {
        localStorage.setItem("_pt", JSON.stringify(res.data.result));
        navigate(routes.dashboard.list!.path);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  return (
    <div>
      <div className="flex w-screen h-screen overflow-auto">
        <div className="container h-full">
          <div className="flex flex-col h-full max-w-md mx-auto px-5 justify-center items-center">
            <img
              src={require("assets/images/logo.png")}
              alt=""
              className="w-52"
            />
            <div className="mt-16 text-4xl text-primary font-semibold">
              Login
            </div>
            <div className="mt-2 mb-5 text-lg text-gray-400 font-semibold">
              Please login to StreamerDap
            </div>
            <div className="mt-2 w-full label-color">
              <Form
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Email is required" },
                    { 
                      whitespace:true,
                      message: "Please enter a valid email" 
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter your email"
                    size="large"
                    autoComplete="off"
                    prefix={<UserOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: "Password is required" }]}
                >
                  <Input.Password
                    placeholder="Enter your password"
                    size="large"
                    autoComplete="off"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
                <Form.Item className="pt-5">
                  <Button type="primary" htmlType="submit" size="large" block>
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
