import moment from "moment";

export const formattedDateTime = (currentDateString:string) => { 
    if(currentDateString){
        const currentDate = new Date(currentDateString); 
        return moment(currentDate).format('MM/DD/YYYY  HH:mm:ss');
    }else{
        return '-';
    }
}

