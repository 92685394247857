import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/brands";
import { routes } from "routes";
import TableComponent from "components/table";
import {
  Button,
  Image,
} from "antd";
import React from "react";
import { EyeOutlined, SettingOutlined } from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

const Brands = () => {
  const navigate = useNavigate();
  const columns = [
   
    {
      title: "Image",
      dataIndex: "avatar",
      sorter: false,
      dataType: "round_image",
      render:(avatar:any)=>{
        return  avatar &&   <Image
            height={40}
            width={40}
            className="object-cover	 rounded-full"
            src={avatar}
        />
      }
    },
    {
      title: "Brand Name",
      dataIndex: "brandEntityName",
      sorter: false,
      dataType: "text",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      dataType: "text",
      render:(email:any)=>{
        return  email ||  '-'
      }
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNo",
      sorter: false,
      dataType: "text",
      render:(phoneNo:any)=>{
        return  phoneNo ||  '-'
      }
    },
    {
      title: "Role",
      dataIndex: "brandEntityTypeName",
      sorter: false,
      dataType: "text",
    },
    { title: "Actions",
      dataType: "actions",
      width: 96 ,
      render: (row: any) => {
        return (
            <>
              <Button
                  type="text"
                  className="ant-btn-icon-only"
                  onClick={() => {
                    navigate(`${String(routes?.brands?.view?.path)?.replaceAll(":id", row.id)}`);
                  }}
              >
                <EyeOutlined />
              </Button>

                  <Button
                      type="text"
                      className="ant-btn-icon-only"
                      onClick={() => {
                        navigate(`${String(routes?.brands?.setting?.path)?.replaceAll(":id", row.id)}`);
                      }}
                  >
                    <SettingOutlined />
                  </Button>

            </>
        )
      }
    },
  ];

  return (
    <PageContainer
      title="Brands"
      route={{
        "Agency Types": routes.agencyTypes.list!,
      }}
    >
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        columns={columns}
        customAction={true}
        viewRoute={routes.brands.view}
        settingRoute={routes.brands.setting}
        classname_table={'brand_table'}
      />
    </PageContainer>
  );
};

export default Brands;
