export enum MenuActionKind {
  MOBILE_ON = "MOBILE_ON",
  MOBILE_OFF = "MOBILE_OFF",
  COLLAPSED_ON = "COLLAPSED_ON",
  COLLAPSED_OFF = "COLLAPSED_OFF",
}

export interface MenuAction {
  type: MenuActionKind;
}

export interface MenuState {
  fullWidth: number;
  collapsedWidth: number;
  collapsed: boolean;
  headerHeight: number;
  isMobile: boolean;
}

const initialState: MenuState = {
  fullWidth: 256,
  collapsedWidth: window.screen.width < 992 ? 0 : 60,
  collapsed: window.screen.width < 992,
  headerHeight: 80,
  isMobile: window.screen.width < 992,
};

const menuReducer = (state: MenuState = initialState, action: MenuAction) => {
  switch (action.type) {
    case MenuActionKind.MOBILE_ON:
      return { ...state, isMobile: true, collapsedWidth: 0, collapsed: true };
    case MenuActionKind.MOBILE_OFF:
      return {
        ...state,
        isMobile: false,
        collapsedWidth: 60,
        collapsed: false,
      };
    case MenuActionKind.COLLAPSED_ON:
      return { ...state, collapsed: true };
    case MenuActionKind.COLLAPSED_OFF:
      return { ...state, collapsed: false };
    default:
      return state;
  }
};

export default menuReducer;
