import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/creators";
import { routes } from "routes";
import TableComponent from "components/table";
import React from "react";
import {Button, Image} from "antd";
import {useNavigate} from "react-router-dom";
import { EyeOutlined, SettingOutlined } from "@ant-design/icons";

const Creators = () => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "Image",
      dataIndex: "avatar",
      sorter: false,
      dataType: "round_image",
      render:(avatar:any)=>{
        return  avatar &&   <Image
            height={40}
            width={40}
            className="object-cover	 rounded-full"
            src={avatar}
        />
      }
    },
    {
      title: "Name / Agent Name",
      dataIndex: "agentFullName",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      dataType: "text",
      render:(email:any)=>{
        return  email ||  '-'
      }
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNo",
      sorter: true,
      dataType: "text",
      render:(phoneNo:any)=>{
        return  phoneNo ||  '-'
      }
    },
    {
      title: "Role",
      dataIndex: "creatorEntityTypeName",
      sorter: false,
      dataType: "text",
    },
    { title: "Actions",
      dataType: "actions",
      width: 96,
      render: (row: any) => {
        return (
            <>
              <Button
                  type="text"
                  className="ant-btn-icon-only"
                  onClick={() => {
                    navigate(`${String(routes?.creators?.view?.path)?.replaceAll(":id", row.id)}`);
                  }}
              >
                <EyeOutlined />
              </Button>

              <Button
                  type="text"
                  className="ant-btn-icon-only"
                  onClick={() => {
                    navigate(`${String(routes?.creators?.setting?.path)?.replaceAll(":id", row.id)}`);
                  }}
              >
                <SettingOutlined />
              </Button>

            </>
        )
      }
    },
  ];

  return (
    <PageContainer
      title="Creators"
      route={{
      }}
    >
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        customAction={true}
        columns={columns}
        viewRoute={routes.creators.view}
        settingRoute={routes.creators.setting}
        classname_table={'brand_table'}
      />
    </PageContainer>
  );
};

export default Creators;
