import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/agencyTypes";
import { routes } from "routes";
import TableComponent from "components/table";

const AgencyTypes = () => {
  const columns = [
    
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      dataType: "text",
    },
    { title: "Actions", dataType: "actions", width: 96 },
  ];

  return (
    <PageContainer title="Agency Types" route={{ Create: routes.agencyTypes.create! }}>
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        columns={columns}
        editRoute={routes.agencyTypes.edit}
        deleteMsgTitle="Delete the Agency Type"
        deleteMsgDescription="Are you sure to delete this Agency Type?"
      />
    </PageContainer>
  );
};

export default AgencyTypes;
