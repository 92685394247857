import axiosInstance from "utils/axiosInterceptor";

export function list(data: any) {
  return axiosInstance({
    url: "/agency/list?" + data,
    method: "get",
  });
}

export function create(data: any) {
  return axiosInstance({
    url: "/agency/create",
    method: "post",
    data: data,
  });
}

export function updateById(data: any) {
  return axiosInstance({
    url: "/agency/updateById/" + data.id,
    method: "put",
    data: data,
  });
}

export function getById(id: string) {
  return axiosInstance({
    url: "/agency/getById/" + id,
    method: "get",
  });
}

export function deleteById(id: string) {
  return axiosInstance({
    url: "/agency/deleteById/" + id,
    method: "delete",
  });
}

export const apiMethod = { list, create, updateById, getById, deleteById };
