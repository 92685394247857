import { Dropdown } from "antd";
import { CaretDownOutlined, LogoutOutlined } from "@ant-design/icons";
import { logout } from "api/auth";

const UserProfileComponent = () => {
  const user = JSON.parse(localStorage.getItem("_pt") ?? "");

  const onLogout = () => {
    logout()
      .then(() => {
        localStorage.removeItem("_pt");
        window.location.href = `${window.location.origin}/login`;
      })
      .catch(() => {
        localStorage.removeItem("_pt");
        window.location.href = `${window.location.origin}/login`;
      });
  };

  const items = [
    {
      key: "1",
      label: <div onClick={onLogout}>Logout</div>,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Dropdown trigger={["click"]} placement="bottom" menu={{ items }}>
      <button className="h-full cursor-pointer font-semibold bg-transparent text-white border-0">
        <div className="space-x-1">
          <span>{user.email}</span>
          <CaretDownOutlined />
        </div>
      </button>
    </Dropdown>
  );
};

export default UserProfileComponent;
