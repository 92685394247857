import React, { ReactNode } from "react";
import PageHeader from "components/pageHeader";
import { RoutesObjInterface } from "routes";

export interface PageContainerInterface {
  children: ReactNode;
  title: string;
  subTitle?: string;
  route?: {[key: string]: RoutesObjInterface};
}

const PageContainer: React.FC<PageContainerInterface> = ({
  children,
  title,
  subTitle,
  route,
}) => {
  return (
    <div>
      <PageHeader title={title} subTitle={subTitle} route={route} />
      <div className="w-full px-5 py-2">{children} </div>
    </div>
  );
};

export default PageContainer;
