import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Row, Col, Button, App, Input, InputNumber } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/creators";
import { routes } from "routes";

const CreatorSetting = () => {
  const [data, setData] = useState<any>({});
  const { id } = useParams();
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const verifyStatusChangeApi = (status: any) => {
    apiMethod
      .updateVerifiedByAdminById(data.id, status)
      .then((res: any) => {
        setData({ ...data, verifiedByAdmin: status });
        message.success(res.data.message);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  const updateCcvToAmountById = () => {
    var ccvToAmount = form.getFieldValue("ccvToAmount");
    apiMethod
      .updateCcvToAmountById(data.id,ccvToAmount)
      .then((res: any) => {
        setData({ ...data, ccvToAmount });
        message.success(res.data.message);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  return (
    <>
      <PageHeader
        title={id && data ? data["agentFullName"] : "New Creator"}
        subTitle="Creator"
      />
      <FormComponent
        routes={routes.creators}
        apiMethod={apiMethod}
        id={id}
        setData={setData}
        form={form}
        canSave={false}
        canNew={false}
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item label="Verification Status">
                {data.verifiedByAdmin === 1 ? (
                  <div className="flex items-center space-x-4">
                    <Button
                      type="primary"
                      size="large"
                      block
                      onClick={() => {
                        verifyStatusChangeApi(2);
                      }}
                    >
                      Verify
                    </Button>
                    <Button
                      type="primary"
                      className="bg-red-500"
                      size="large"
                      block
                      onClick={() => {
                        verifyStatusChangeApi(3);
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                ) : data.verifiedByAdmin === 2 ? (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/verified.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-verified text-lg">Verified</label>
                  </div>
                ) : data.verifiedByAdmin === 3 ? (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/rejected.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-red-500 text-lg">Rejected</label>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/not_verified.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-notVerified text-lg">
                      Not Verified
                    </label>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item label="Creator CPM" name="ccvToAmount" className="mb-2 ">
                <InputNumber autoComplete="off" className="w-full"/>
              </Form.Item>
              <div className="flex items-center space-x-4">
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={() => {
                    updateCcvToAmountById();
                  }}
                  className="w-1/2"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </FormComponent>
    </>
  );
};

export default CreatorSetting;
