import { Button, Popconfirm, Space } from "antd";
import { ArrowLeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import DeleteActionButton from "components/button/deleteActionButton";
import SaveActionButton from "components/button/saveActionButton";
import NewActionButton from "components/button/newActionButton";
import { RoutesInterface } from "routes";

export interface ActionBarComponentInterface {
  saveClick?: () => void;
  newClick?: () => void;
  deleteClick?: () => void;
  routes: RoutesInterface;
  canBack: boolean;
  deleteMsgTitle?: string;
  deleteMsgDescription?: string;
}

const ActionBarComponent: React.FC<ActionBarComponentInterface> = ({
  saveClick,
  newClick,
  deleteClick,
  routes,
  canBack,
  deleteMsgTitle,
  deleteMsgDescription,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="w-full rounded-md flex items-center bg-card text-white p-3
    "
    >
      <Space size="small">
        {canBack && (
          <Button
            type="text"
            onClick={() => {
              navigate(routes.list!.path);
            }}
            className="border border-gray-200 text-white"
          >
            <ArrowLeftOutlined className="text-white text-base"></ArrowLeftOutlined>
          </Button>
        )}
        {saveClick && <SaveActionButton onClick={saveClick} />}
        {newClick && <NewActionButton onClick={newClick} />}
        {deleteClick && (
          <Popconfirm
            placement="bottom"
            title={deleteMsgTitle ?? "Delete the recod"}
            description={
              deleteMsgDescription ?? "Are you sure to delete this record?"
            }
            ok-text="Yes"
            cancel-text="No"
            onConfirm={deleteClick}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <DeleteActionButton />
          </Popconfirm>
        )}
      </Space>
    </div>
  );
};

export default ActionBarComponent;
