import React from "react";
import { Form, Upload, message } from "antd";
import { PlusOutlined, CloseCircleFilled } from "@ant-design/icons";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import type { UploadChangeParam } from "antd/es/upload";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export interface UploadFileFormInterface {
  setImageFile: any;
  setImageUrl: (arg0: string) => void;
  image: string;
  isValidateType?: boolean;
  allowedTypesList?: string[];
  allowedTypesMsg?: string;
  isValidateDimension?: boolean;
  allowedDimensionMsg?: string;
  allowedWidth?: number;
  allowedHeight?: number;
}
const getImageDimensions = async (
  file: any
): Promise<{ width: number; height: number }> => {
  let img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();
  let width = img.width;
  let height = img.height;
  return {
    width,
    height,
  };
};
const UploadFileForm: React.FC<UploadFileFormInterface> = ({
  setImageFile,
  setImageUrl,
  image,
  isValidateType = false,
  allowedTypesList = ["image/jpeg", "image/png", "image/jpg"],
  allowedTypesMsg = "Please upload an image in PNG and JPEG formats.",
  isValidateDimension = false,
  allowedDimensionMsg = "Image dimensions must be 300x300 pixels.",
  allowedWidth = 300,
  allowedHeight = 300,
}) => {
  const handleChange: UploadProps["onChange"] = async (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "done") {
      setImageFile(info.file.originFileObj);
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = async (file: File) => {
    if (isValidateType) {
      const isValidImage = allowedTypesList.includes(file.type);
      if (!isValidImage) {
        message.error(allowedTypesMsg);
        return false;
      }
    }
    if (isValidateDimension) {
      const { width, height } = await getImageDimensions(file);
      if (width !== allowedWidth || height !== allowedHeight) {
        message.error(allowedDimensionMsg);
        return false;
      }
    }
    return true;
  };

  return (
    <Form.Item name="image2" label="Image">
      <div className="w-28 h-28 relative">
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader mt-2 text-white"
          showUploadList={false}
          onChange={handleChange}
          customRequest={(e: any) => e.onSuccess()}
          maxCount={1}
          beforeUpload={(file) => beforeUpload(file)}
        >
          {image ? (
            <img
              src={image}
              alt="avatar"
              className="w-full h-full object-contain"
            />
          ) : (
            uploadButton
          )}
        </Upload>
        {image && (
          <CloseCircleFilled
            className="absolute top-0 right-0 text-red-500 mr-0.5 text-lg"
            onClick={() => {
              setImageFile(null);
              setImageUrl("");
            }}
          />
        )}
      </div>
    </Form.Item>
  );
};

export default UploadFileForm;
