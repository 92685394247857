import React, { useState } from "react";
import { Form, InputNumber, Row, Col } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/settings";
import { routes } from "routes";

const Settings = () => {
  const [data, setData] = useState<any>({});
  const [form] = Form.useForm();
  
  return (
    <>
      <PageHeader
        title="Settings"
        subTitle=""
      />
      <FormComponent
        routes={routes.settings}
        apiMethod={apiMethod}
        setData={setData}
        form={form}
        canSave={true}
        canNew={false}
        id="1"
        canBack={false}
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item
                name="1USD=?DAP"
                label="1 USD = ? DAP"
                rules={[
                  {
                    required: true,
                    message: "Dap is required",
                  },
                ]}
              >
                <InputNumber autoComplete="off" className="w-full" min={0}/>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </FormComponent>
    </>
  );
};

export default Settings;
