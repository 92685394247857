import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Row, Col, Button, App, Input, InputNumber } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/brands";
import { routes } from "routes";

const BrandSetting = () => {
  const [data, setData] = useState<any>({});
  const { id } = useParams();
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const verifyStatusChangeApi = (status: any) => {
    apiMethod
      .updateVerifiedByAdminById(data.id, status)
      .then((res: any) => {
        setData({ ...data, verifiedByAdmin: status });
        message.success(res.data.message);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  const paymentTermChangeApi = (status: any) => {
    apiMethod
      .updatePaymentTermById(data.id, status, data.paymentTerm)
      .then((res: any) => {
        setData({ ...data, paymentTermStatus: status });
        message.success(res.data.message);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  const updateCPMByIdApi = () => {
    var cpm = form.getFieldValue("cpm");
    apiMethod
      .updateCPMById(data.id,cpm)
      .then((res: any) => {
        setData({ ...data, cpm });
        message.success(res.data.message);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  const getPaymentTermNameById = () => {
    switch (`${data.paymentTerm}`) {
      case "1":
        return "Pay Up Front";
      case "2":
        return "Net 15";
      case "3":
        return "Net 30";
      case "4":
        return "Net 60";
    }
  };

  return (
    <>
      <PageHeader
        title={id && data ? data["name"] : "Create Brand"}
        subTitle="Brand"
      />
      <FormComponent
        routes={routes.brands}
        apiMethod={apiMethod}
        id={id}
        setData={setData}
        form={form}
        canSave={false}
        canNew={false}
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item label="Verification Status">
                {data.verifiedByAdmin === 1 ? (
                  <div className="flex items-center space-x-4">
                    <Button
                      type="primary"
                      size="large"
                      block
                      onClick={() => {
                        verifyStatusChangeApi(2);
                      }}
                    >
                      Verify
                    </Button>
                    <Button
                      type="primary"
                      className="bg-red-500"
                      size="large"
                      block
                      onClick={() => {
                        verifyStatusChangeApi(3);
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                ) : data.verifiedByAdmin === 2 ? (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/verified.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-verified text-lg">Verified</label>
                  </div>
                ) : data.verifiedByAdmin === 3 ? (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/rejected.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-red-500 text-lg">Rejected</label>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/not_verified.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-notVerified text-lg">
                      Not Verified
                    </label>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item label="Payment Term">
                {data.paymentTermStatus === 1 ? (
                  <div>
                    <Input
                      autoComplete="off"
                      disabled
                      value={getPaymentTermNameById()}
                      className="mb-2"
                    />
                    <div className="flex items-center space-x-4">
                      <Button
                        type="primary"
                        size="large"
                        block
                        onClick={() => {
                          paymentTermChangeApi(2);
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        type="primary"
                        className="bg-red-500"
                        size="large"
                        block
                        onClick={() => {
                          paymentTermChangeApi(3);
                        }}
                      >
                        Reject
                      </Button>
                    </div>
                  </div>
                ) : data.paymentTermStatus === 2 ? (
                  <div>
                    <Input
                      autoComplete="off"
                      disabled
                      value={getPaymentTermNameById()}
                      className="mb-2"
                    />
                    <div className="flex items-center">
                      <img
                        src={require("assets/images/verified.png")}
                        alt=""
                        className="w-8 mr-2"
                      />
                      <label className="text-verified text-lg">Approved</label>
                    </div>
                  </div>
                ) : data.paymentTermStatus === 3 ? (
                  <div>
                    <Input
                      autoComplete="off"
                      disabled
                      value={getPaymentTermNameById()}
                      className="mb-2"
                    />
                    <div className="flex items-center">
                      <img
                        src={require("assets/images/rejected.png")}
                        alt=""
                        className="w-8 mr-2"
                      />
                      <label className="text-red-500 text-lg">Rejected</label>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/not_verified.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-notVerified text-lg">
                      Not Approved
                    </label>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item label="CPM" name="cpm" className="mb-2 ">
                <InputNumber autoComplete="off" className="w-full" min={0}/>
              </Form.Item>
              <div className="flex items-center space-x-4">
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={() => {
                    updateCPMByIdApi();
                  }}
                  className="w-1/2"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </FormComponent>
    </>
  );
};

export default BrandSetting;
