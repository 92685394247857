import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/categories";
import { routes } from "routes";
import TableComponent from "components/table";

export interface CategoriesInterface {
  type: "Brand" | "Creator";
}
const Categories: React.FC<CategoriesInterface> = ({ type }) => {

  const columns = [
    {
      title: "Primary Category",
      dataIndex: "primaryCategory",
      sorter: true,
      dataType: "text",
    },
    {
      title: type === "Creator" ? "Sub Category" : "Title",
      dataIndex: "title",
      sorter: true,
      dataType: "text",
    },
    {
      title: "Image",
      dataIndex: "image",
      sorter: false,
      dataType: "image",
    },
    { title: "Actions", dataType: "actions", width: 96 },
  ];

  return (
    <PageContainer
      title={`${type} Categories`}
      route={{
        Create:
          type === "Brand"
            ? routes.brandCategories.create!
            : routes.creatorCategories.create!,
      }}
    >
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        columns={type === "Creator" ? columns : columns?.filter(e => e.title !== "Primary Category")}
        editRoute={
          type === "Brand"
            ? routes.brandCategories.edit
            : routes.creatorCategories.edit
        }
        deleteMsgTitle={`Delete the ${type} Category`}
        deleteMsgDescription={`Are you sure delete this ${type} Category?`}
        otherGetDataFunc={(obj: any) => {
          obj.type = type === "Brand" ? 1 : 2;
          return obj;
        }}
      />
    </PageContainer>
  );
};

export default Categories;
