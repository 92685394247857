export enum BrandEntityType {
  Brand = 1,
  Agency = 2,
}

export enum CreatorEntityType {
  Creator = 1,
  Agency = 2,
}

export enum BrandTransactionStatus {
  Unpaid = 1,
  Paid = 2,
}