import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Input,
  Row,
  Col,
  App,
  DatePicker,
  Divider,
  Image,
  Button,
  Table,
} from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/campaigns";
import { routes } from "routes";
import TextArea from "antd/es/input/TextArea";
import TableComponent from "../../components/table";

const CampaignManage = () => {
  const [data, setData] = useState<any>({});
  const [dataAssets, setDataAssets] = useState<any>();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { message } = App.useApp();

  useEffect(() => {
    apiMethod
      .getAssetsById(`${id}`)
      .then((res: any) => {
        setDataAssets(res.data.result);
      })
      .catch((err: any) => {});
  }, []);

  const verifyRequestStatusChangeApi = (status: any) => {
    apiMethod
      .updateRequestStatusById(data.id, status)
      .then((res: any) => {
        setData({ ...data, requestStatus: status });
        message.success(res.data.message);
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  return (
    <>
      <PageHeader
        title={
          id && data
            ? data["title"]
            : window.location.href.includes("campaignRequest")
            ? "New Campaign Request"
            : "New Campaign"
        }
        subTitle={
          window.location.href.includes("campaignRequest")
            ? "Campaign Request"
            : "Campaign"
        }
      />
      <FormComponent
        routes={
          window.location.href.includes("campaignRequest")
            ? routes.campaignRequests
            : routes.campaigns
        }
        apiMethod={apiMethod}
        id={id}
        setData={setData}
        form={form}
        canSave={false}
        canNew={false}
        changeGetData={(obj: any) => {
          obj = { ...obj, ...obj.kpi };
          obj.creators = obj.creators.map((e: any) => {
            var obj = e.creatorDetail;
            obj.totalCost = `$${obj.totalCost ?? 0}`;
            return obj;
          });
          Object.keys(obj.reports).forEach((e) => {
            obj[`reports_${e}`] = obj.reports[e];
          });
          delete obj.kpi;
          return obj;
        }}
        dateChangeParam={["startedAt"]}
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item label="Request Account Rep Review">
                {data.requestStatus === 1 ? (
                  <div className="flex items-center space-x-4">
                    <Button
                      type="primary"
                      size="large"
                      block
                      onClick={() => {
                        verifyRequestStatusChangeApi(2);
                      }}
                    >
                      Verify
                    </Button>
                    <Button
                      type="primary"
                      className="bg-red-500"
                      size="large"
                      block
                      onClick={() => {
                        verifyRequestStatusChangeApi(3);
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                ) : data.requestStatus === 2 ? (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/verified.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-verified text-lg">Verified</label>
                  </div>
                ) : data.requestStatus === 3 ? (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/rejected.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-red-500 text-lg">Rejected</label>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <img
                      src={require("assets/images/not_verified.png")}
                      alt=""
                      className="w-8 mr-2"
                    />
                    <label className="text-notVerified text-lg">
                      Not Verified
                    </label>
                  </div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item name="title" label="Title">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="campaignStatusName" label="Status">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="startedAt" label="Start Date">
                <DatePicker
                  autoComplete="off"
                  disabled
                  className="w-full"
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="budget" label="Budget">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="daps" label="Daps">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="days" label="Campaign Period">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="cpm" label="CPM">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="impressions" label="Impressions">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="mediaPlacements" label="Media Placements">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
          </Row>
          <label>Creators</label>
          {data.creators && (
            <TableComponent
              apiMethod={apiMethod}
              apiKeyNames={{ list: "list" }}
              data={{ data: data.creators }}
              isPagination={false}
              columns={[
                {
                  title: "Image",
                  dataIndex: "avatar",
                  sorter: false,
                  dataType: "round_image",
                },
                {
                  title: "Name",
                  dataIndex: "agentFullName",
                  sorter: false,
                  dataType: "text",
                },
                {
                  title: "Company Name",
                  dataIndex: "companyName",
                  sorter: false,
                  dataType: "text",
                },
                Table.EXPAND_COLUMN,
              ]}
              expandableView={(record: any) => {
                return (
                  <Row gutter={16}>
                    <Col span={24} sm={12}>
                      <Form.Item label="CCV">
                        <Input autoComplete="off" disabled value={record.ccv} />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Clicks">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.clicks}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Conversion">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.conversion}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Cost Per Click">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.costPerClick}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="CTA">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.cta}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Followers">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.followers}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Impressions">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.impressions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Total Cost">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.totalCost}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12}>
                      <Form.Item label="Total Viewers">
                        <Input
                          autoComplete="off"
                          disabled
                          value={record.totalviewers}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }}
            />
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Divider
                orientation="left"
                orientationMargin="0px"
                className="text-xl"
              >
                Assets
              </Divider>
            </Col>
          </Row>
          {dataAssets && (
            <>
              <Form.Item label="Banners or Logos">
                <Row gutter={16}>
                  <Col span={8} className="flex justify-center items-center">
                    <Image src={dataAssets.image1} className="object-contain" />
                  </Col>
                  <Col span={8} className="flex justify-center items-center">
                    <Image src={dataAssets.image2} className="object-contain" />
                  </Col>
                  <Col span={8} className="flex justify-center items-center">
                    <Image src={dataAssets.image3} className="object-contain" />
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Landing Page screenshot">
                <Row gutter={16}>
                  <Col span={8} className="flex justify-center items-center">
                    <Image
                      src={dataAssets.landingPageScreenshot}
                      className="object-contain"
                    />
                  </Col>
                </Row>
              </Form.Item>
              <Row gutter={16}>
                <Col span={24} sm={12}>
                  <Form.Item label="Offer Link">
                    <Input
                      autoComplete="off"
                      disabled
                      value={dataAssets.offerLink}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Divider
                orientation="left"
                orientationMargin="0px"
                className="text-xl"
              >
                Reports
              </Divider>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item name="reports_impressions" label="Impressions">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item
                name="reports_mediaPlacements"
                label="Media Placements"
              >
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_linkClicks" label="Clicks">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_cpm" label="CPM">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_brandReads" label="CTAs/Brand Reads">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_engagementRate" label="Engagement Rate">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="reports_conversionRate" label="Conversion Rate">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </FormComponent>
    </>
  );
};

export default CampaignManage;
