import axios from "axios";
import { useDispatch } from "react-redux";
import { logout } from "api/auth";
import { otherRoutes } from "routes";
import { LoaderAction, LoaderActionKind } from "reducers/loader";
import { refreshToken } from "api/auth";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
  timeout: 0, // no timeout
});

const logouts = () => {
  logout()
    .then(() => {
      localStorage.removeItem("_pt");
      window.location.href = `${window.location.origin}${otherRoutes.login.path}`;
    })
    .catch(() => {
      localStorage.removeItem("_pt");
      window.location.href = `${window.location.origin}${otherRoutes.login.path}`;
    });
};

const AxiosInterceptor: React.FC<any> = ({ children }) => {
  const dispatch = useDispatch();
  axiosInstance.interceptors.request.use(
    (config) => {
      const action: LoaderAction = {
        type: LoaderActionKind.MAIN_LOADER_START,
      };
      dispatch(action);
      const publicPages = ["/User/sign-in"];
      const authRequired = !publicPages.includes(config.url ?? "");
      if (authRequired) {
        const authToken =
          localStorage.getItem("_pt") &&
          JSON.parse(localStorage.getItem("_pt") ?? "")["accessToken"];
        if (authToken) {
          config.headers["Authorization"] = "Bearer " + authToken;
        }
      }
      return config;
    },
    (err) => {
      const action: LoaderAction = {
        type: LoaderActionKind.MAIN_LOADER_STOP,
      };
      dispatch(action);
      return Promise.reject(err);
    }
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      const action: LoaderAction = {
        type: LoaderActionKind.MAIN_LOADER_STOP,
      };
      dispatch(action);
      return response;
    },
    function (err) {
      const action: LoaderAction = {
        type: LoaderActionKind.MAIN_LOADER_STOP,
      };
      dispatch(action);
      console.log(err);
      const originalRequest = err.config;

      if (err.response && err.response.status === 401) {
        if (
          err.config &&
          err.config.url !== "/refreshToken" &&
          localStorage.getItem("_pt")
        ) {
          const user = JSON.parse(localStorage.getItem("_pt") ?? "");
          return refreshToken(user)
            .then((response) => {
              localStorage.setItem(
                "_pt",
                JSON.stringify({ ...user, ...response.data.result })
              );
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              logouts();
            });
        } else {
          logouts();
        }
      }
      return Promise.reject(err.response);
    }
  );
  return children;
};

export default axiosInstance;
export { AxiosInterceptor };
