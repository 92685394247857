import React, { ReactNode } from "react";
import Header from "layouts/components/header";
import Sidebar from "layouts/components/sidebar";
import { useSelector } from "react-redux";
import { MenuState } from "reducers/menu";

export interface DefaultInterface {
  children: ReactNode;
}

const Default: React.FC<DefaultInterface> = ({ children }) => {
  const menuState: MenuState = useSelector((state: any) => state.menuReducer);

  return (
    <>
      <Header />
      <div
        className="overflow-y-scroll"
        style={{
          marginTop: `${menuState.headerHeight}px`,
          height: `calc(100vh - ${menuState.headerHeight}px)`,
        }}
      >
        <Sidebar />
        <div
          style={{
            paddingLeft: menuState.isMobile
              ? "0px"
              : `${
                  menuState.collapsed
                    ? menuState.collapsedWidth
                    : menuState.fullWidth
                }px`,
          }}
          className="transition-all duration-200 w-screen"
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Default;
