import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/companies";
import { routes } from "routes";
import TableComponent from "components/table";

const Companies = () => {
  const columns = [
   
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      dataType: "text",
    },
    { title: "Actions", dataType: "actions", width: 96 },
  ];

  return (
    <PageContainer
      title="Companies"
      route={{ Create: routes.companies.create! }}
    >
      <TableComponent
        apiMethod={apiMethod}
        apiKeyNames={{ list: "list" }}
        columns={columns}
        editRoute={routes.companies.edit}
        deleteMsgTitle="Delete the Company?"
        deleteMsgDescription="Are you sure delete this Company?"
      />
    </PageContainer>
  );
};

export default Companies;
