import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";

export interface NewActionButtonInterface {
  onClick?: () => void;
}

const NewActionButton: React.FC<NewActionButtonInterface> = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick} style={{ background: '#1890ff' }}>
      <PlusOutlined className="text-white-500" />
      New
    </Button>
  );
};

export default NewActionButton;
