import { combineReducers } from "redux";
import menuReducer from "reducers/menu";
import loaderReducer from "reducers/loader";

const rootReducer = combineReducers({
  menuReducer,
  loaderReducer,
});

export default rootReducer;
