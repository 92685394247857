import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

export interface DeleteActionButtonInterface {
  onClick?: () => void;
}

const DeleteActionButton: React.FC<DeleteActionButtonInterface> = ({
  onClick,
}) => {
  return (
    <Button type="text" onClick={onClick}  danger style={{ background: '#ff4d4f' }}>
      <DeleteOutlined className="text-white-500" />
      Delete
    </Button>
  );
};

export default DeleteActionButton;
