import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Layout, ConfigProvider, App as Apps } from "antd";
import Default from "layouts/default";
import { AxiosInterceptor } from "utils/axiosInterceptor";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  routes,
  otherRoutes,
  RoutesObjInterface,
  RoutesInterface,
} from "routes";
import LoaderComponent from "components/loader";
import { MenuState, MenuAction, MenuActionKind } from "reducers/menu";

function App() {
  const dispatch = useDispatch();
  const menuState: MenuState = useSelector((state: any) => state.menuReducer);

  window.onresize = function (event: UIEvent) {
    if (
      menuState.isMobile &&
      event &&
      event.target &&
      (event.target as Window).screen.width > 991
    ) {
      const action: MenuAction = {
        type: MenuActionKind.MOBILE_OFF,
      };
      dispatch(action);
    } else if (
      !menuState.isMobile &&
      event &&
      event.target &&
      (event.target as Window).screen.width < 992
    ) {
      const action: MenuAction = {
        type: MenuActionKind.MOBILE_ON,
      };
      dispatch(action);
    }
  };

  
  return (
    <AxiosInterceptor>
      <Layout className="bg-white bg-center bg-no-repeat bg-cover bg-[url('assets/images/bg.svg')] overflow-hidden">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#6cb557",
            },
          }}
        >
          <Apps>
            <LoaderComponent>
              <Router>
                <Routes>
                  <Route
                    path={otherRoutes.login.path}
                    element={<Auth />}
                  ></Route>
                  <Route
                    path={otherRoutes.error.path}
                    element={<Authenticate />}
                  ></Route>
                </Routes>
              </Router>
            </LoaderComponent>
          </Apps>
        </ConfigProvider>
      </Layout>
    </AxiosInterceptor>
  );
}

function Auth() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("_pt")) {
      navigate(routes.dashboard.list!.path);
    }
  });

  return (
    <Routes>
      <Route path="/*" element={otherRoutes.login.element}></Route>
    </Routes>
  );
}

function Authenticate() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("_pt")) {
      navigate(otherRoutes.login.path);
    }
  });

  if (!localStorage.getItem("_pt")) {
    return <div />;
  }

  const setRoutes = () => {
    var arr: RoutesObjInterface[] = [];
    Object.values(routes).forEach((e: RoutesInterface) => {
      e.list && arr.push(e.list);
      e.create && arr.push(e.create);
      e.edit && arr.push(e.edit);
      e.view && arr.push(e.view);
      e.setting && arr.push(e.setting);
    });

    return arr;
  };

  return (
    <Default>
      <Routes>
        {setRoutes().map((e, i) => (
          <Route key={i} path={e.path} element={e.element} />
        ))}
         <Route path={otherRoutes.error.path} element={otherRoutes.error.element} />
      </Routes>
    </Default>
  );
}

export default App;
