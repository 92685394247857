import PageContainer from "layouts/components/pageContainer";
import { apiMethod } from "api/ratings";
import { routes } from "routes";
import { useNavigate } from "react-router-dom";
import TableComponent from "components/table";
import {
    EyeOutlined,
} from "@ant-design/icons";
import {Button, Rate} from "antd";
import React from "react";
import moment from "moment";

const Ratings = () => {
    const navigate = useNavigate();

    const columns = [
        {
            title: "Date",
            dataIndex: "createdAt",
            sorter: false,
            dataType: "text",
            render:(createdAt:any)=>{
                return createdAt ? moment(createdAt).format('MM/DD/YYYY') : '-';
            }
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: true,
            dataType: "text",
            render:(name:any)=>{
                return  name ||  '-'
            }
        },
        {
            title: "Email",
            dataIndex: "email",
            sorter: false,
            dataType: "text",
            render:(email:any)=>{
                return  email ||  '-'
            }
        },
        {
            title: "User Type",
            dataIndex: "userEntityTypeName",
            sorter: false,
            dataType: "text",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            sorter: false,
            dataType: "text",
            render:(comment:string)=>{
                return<span>{comment?.slice(0,30)}{comment?.length >= 30 && '....'}</span>
            }
        },
        {
            title: "Rating",
            dataIndex: "stars",
            sorter: false,
            dataType: "rate",
            render:(val: any, row: any, index: number)=>{
                return   <Rate disabled defaultValue={val} className="w-36" />
            }
        },
        { title: "Actions", dataType: "actions", width: 96, render: (row: any) => {
                return (
                    <Button
                        type="text"
                        className="ant-btn-icon-only"
                        onClick={() => {
                            navigate(`${String(routes?.ratings?.view?.path)?.replaceAll(":id", row.id)}`);
                        }}
                    >
                        <EyeOutlined />
                    </Button>
                )
            } },
    ];

    return (
        <PageContainer
            title="Ratings"
        >
            <TableComponent
                apiMethod={apiMethod}
                apiKeyNames={{ list: "list" }}
                columns={columns}
                customAction={true}
                viewRoute={routes.ratings.view}
                settingRoute={routes.ratings.setting}
            />
        </PageContainer>
    );
};

export default Ratings;
