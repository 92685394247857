import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";

const Errors = () => {
  const navigate = useNavigate();

  const getData = () => {
    if (window.location.pathname === "/forbidden") {
      return {
        status: "403",
        title: "403",
        subTitle: "Sorry, you are not authorized to access this page.",
      };
    } else {
      return {
        status: "404",
        title: "404",
        subTitle: "Sorry, the page you visited does not exist.",
      };
    }
  };

  return (
    <>
      <Result
        status={getData().status as any}
        title={getData().title}
        subTitle={getData().subTitle}
      />
      <div className="flex justify-center">
        <Button type="primary" onClick={() => navigate("/")}>
          Back To Home
        </Button>
      </div>
    </>
  );
};

export default Errors;
