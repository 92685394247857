import React, { useState } from "react";
import {useParams} from "react-router-dom";
import { Form, Input, Row, Col, Rate } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/feedBacks";
import { routes } from "routes";
import TextArea from "antd/es/input/TextArea";

const FeedBackManage = () => {
  const [data, setData] = useState<any>({});
  const { id } = useParams();
  const [form] = Form.useForm();

  return (
    <>
      <PageHeader
        title={id && data ? data["name"] : "Create FeedBack"}
        subTitle="FeedBack"
      />
      <FormComponent
        routes={routes.feedBacks}
        apiMethod={apiMethod}
        id={id}
        setData={setData}
        form={form}
        canSave={false}
        canNew={false}
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <Form.Item name="name" label={"Name"}>
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="email" label="Email">
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="userEntityTypeName" label={"User Type"}>
                <Input autoComplete="off" disabled />
              </Form.Item>
            </Col>
            <Col span={24} sm={12}>
              <Form.Item name="rate" label="Rating">
              <Rate disabled/>
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item name="comment" label="Comment">
                <TextArea
                  autoComplete="off"
                  disabled
                  autoSize={{ minRows: 1, maxRows: 1000 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </FormComponent>

    </>
  );
};

export default FeedBackManage;
