import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Row, Col, Select, App, } from "antd";
import PageHeader from "components/pageHeader";
import FormComponent from "components/form";
import { apiMethod } from "api/categories";
import { routes } from "routes";
import UploadFileForm from "components/uploadFileForm";

export interface CategoryManageInterface {
  type: "Brand" | "Creator";
}

export interface PrimaryCategory {
  id: number;
  title: string
}

const { Option } = Select;
const CategoryManage: React.FC<CategoryManageInterface> = ({ type }) => {
  const [data, setData] = useState<any>({});
  const [imageFile, setImageFile] = useState<any>(null);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [primaryCategory, setPrimaryCategory] = useState<PrimaryCategory[]>([])
  const { id } = useParams();
  const [form] = Form.useForm();
  const { message } = App.useApp();


  const getPrimaryCategoryData = () => {
    apiMethod.getPrimaryCategoryApi()
      .then((res: any) => {
        setPrimaryCategory(res?.data?.result?.data || [])
      })
      .catch((err: any) => {
        if (err && err.data && Array.isArray(err.data.message)) {
          form.setFields(
            err.data.message.map((e: any) => {
              return { name: e.key, errors: [e.msg] };
            })
          );
        } else {
          message.error(`${err.data.message}`);
        }
      });
  };

  useEffect(() => {
    getPrimaryCategoryData()
  }, [])

  return (
    <>
      <PageHeader
        title={id && data ? data["title"] : "Create Category"}
        subTitle={`${type} Category`}
      />
      <FormComponent
        routes={
          type === "Brand" ? routes.brandCategories : routes.creatorCategories
        }
        apiMethod={apiMethod}
        id={id}
        otherData={{
          image: imageFile,
          isDeleteImage: isDelete ? 1 : 0,
          type: type === "Brand" ? 1 : 2,
        }}
        setData={setData}
        form={form}
        canSave={true}
        canNew={true}
        deleteMsgTitle={`Delete the ${type} Category?`}
        deleteMsgDescription={`Are you sure to delete this ${type} Category?`}
      >
        <div className={`max-w-full p-5 category-form`}>
          <Row gutter={16}>
            {
              type === "Creator" &&
              <Col span={12} sm={6} className="category_dropdown">
                <Form.Item
                  label={'Primary Category'}
                  name="primaryCategoryId"
                  rules={[{ required: type === "Creator" ? true : false, message: 'Primary Category Required' }]}
                >
                  <Select
                    style={{ width: '100%', height: "50px" }}
                    placeholder="Select Category"
                  >
                    {
                      primaryCategory?.map((category: PrimaryCategory, i) => {
                        return <Option key={category?.id} value={category?.id} >{category?.title}</Option>
                      })
                    }
                  </Select>
                </Form.Item>

              </Col>
            }
            <Col span={12} sm={6}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Title is required",
                  },
                  {
                    max: 30,
                    message: "Title cannot exceed 30 characters",
                  },
                ]}
              >
                <Input autoComplete="off" maxLength={30} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24} sm={12}>
              <UploadFileForm
                image={data["image"]}
                setImageUrl={(url: string) => {
                  setData({ ...data, image: url });
                }}
                setImageFile={(data: any) => {
                  setIsDelete(true);
                  setImageFile(data);
                }}
                isValidateDimension={true}
                isValidateType={true}
                allowedHeight={120}
                allowedWidth={120}
                allowedDimensionMsg="Image dimensions must be 120x120 pixels."
              />
            </Col>
          </Row>
        </div>
      </FormComponent>
    </>
  );
};

export default CategoryManage;
