/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { useSelector, useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd";
import { routes, RoutesInterface } from "routes";
import { MenuState, MenuAction, MenuActionKind } from "reducers/menu";
import {
  MdOutlineDashboard,
  MdCategory,
  MdOutlineFeedback,
  MdOutlineCampaign,
} from "react-icons/md";
import { HiBuildingOffice2, HiMiniBuildingOffice } from "react-icons/hi2";
import { SiBrandfolder } from "react-icons/si";
import {
  AiOutlineCopyrightCircle,
  AiFillSetting,
  AiOutlineTransaction,
} from "react-icons/ai";

export interface MenuOptionInterface {
  title: string;
  key: string;
  icon: ReactNode;
  isAdd: Boolean;
  route: RoutesInterface;
  otherRoute?: RoutesInterface;
}

const Sidebar = () => {
  const dispatch = useDispatch();
  const menuState: MenuState = useSelector((state: any) => state.menuReducer);
  const [openKeys, setOpenKeys] = useState<string[]>(["sub1"]);
  const rootSubmenuKeys = ["sub1", "sub2"];
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    var arr: string[] = [];
    menuOptions.forEach((e) => {
      if (checkRoute(e) && arr.length === 0) {
        arr = [e.key];
      }
    });
    setSelectedKeys(arr);
  }, [window.location.pathname]);

  const checkRoute = (obj: MenuOptionInterface) => {
    return (
      (obj.route &&
        obj.route.list &&
        obj.route.list.path === window.location.pathname) ||
      (obj.route &&
        obj.route.create &&
        obj.route.create.path === window.location.pathname) ||
      (obj.route &&
        obj.route.edit &&
        obj.route.edit.path &&
        obj.route.edit.path.split("/").slice(0, -1).join("/") ===
          window.location.pathname.split("/").slice(0, -1).join("/")) ||
      (obj.route &&
        obj.route.view &&
        obj.route.view.path &&
        obj.route.view.path.split("/").slice(0, -1).join("/") ===
          window.location.pathname.split("/").slice(0, -1).join("/")) ||
      (obj.route &&
        obj.route.setting &&
        obj.route.setting.path &&
        obj.route.setting.path.split("/").slice(0, -1).join("/") ===
          window.location.pathname.split("/").slice(0, -1).join("/")) ||
      (obj.otherRoute &&
        obj.otherRoute.list &&
        obj.otherRoute.list.path === window.location.pathname) ||
      (obj.otherRoute &&
        obj.otherRoute.create &&
        obj.otherRoute.create.path === window.location.pathname) ||
      (obj.otherRoute &&
        obj.otherRoute.edit &&
        obj.otherRoute.edit.path &&
        obj.otherRoute.edit.path.split("/").slice(0, -1).join("/") ===
          window.location.pathname.split("/").slice(0, -1).join("/")) ||
      (obj.otherRoute &&
        obj.otherRoute.view &&
        obj.otherRoute.view.path &&
        obj.otherRoute.view.path.split("/").slice(0, -1).join("/") ===
          window.location.pathname.split("/").slice(0, -1).join("/")) ||
      (obj.otherRoute &&
        obj.otherRoute.setting &&
        obj.otherRoute.setting.path &&
        obj.otherRoute.setting.path.split("/").slice(0, -1).join("/") ===
          window.location.pathname.split("/").slice(0, -1).join("/"))
    );
  };

  const onOpenChange = (openKeys2: string[]) => {
    const latestOpenKey: string | undefined = openKeys2.find(
      (key: string) => openKeys.indexOf(key) === -1
    );
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeys2);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const menuOptions: MenuOptionInterface[] = [
    {
      title: "Dashboard",
      key: "sub1",
      icon: <MdOutlineDashboard />,
      isAdd: false,
      route: routes.dashboard,
    },
    {
      title: "Brands",
      key: "sub2",
      icon: <SiBrandfolder />,
      isAdd: false,
      route: routes.brands,
      otherRoute: routes.agencyTypes,
    },
    {
      title: "Creators",
      key: "sub3",
      icon: <AiOutlineCopyrightCircle />,
      isAdd: false,
      route: routes.creators,
    },
    {
      title: "Campaigns",
      key: "sub4",
      icon: <MdOutlineCampaign />,
      isAdd: false,
      route: routes.campaigns,
    },
    {
      title: "Campaign Requests",
      key: "sub5",
      icon: <MdOutlineCampaign />,
      isAdd: false,
      route: routes.campaignRequests,
    },
    {
      title: "Brand Categories",
      key: "sub6",
      icon: <MdCategory />,
      isAdd: true,
      route: routes.brandCategories,
    },
    {
      title: "Creator Categories",
      key: "sub7",
      icon: <MdCategory />,
      isAdd: true,
      route: routes.creatorCategories,
    },
    // {
    //   title: "Companies",
    //   key: "sub6",
    //   icon: <HiBuildingOffice2 />,
    //   isAdd: true,
    //   route: routes.companies,
    // },
    // {
    //   title: "Agencies",
    //   key: "sub7",
    //   icon: <HiMiniBuildingOffice />,
    //   isAdd: true,
    //   route: routes.agencies,
    // },
    {
      title: "FeedBacks",
      key: "sub8",
      icon: <MdOutlineFeedback />,
      isAdd: true,
      route: routes.feedBacks,
    },
    {
      title: "Ratings",
      key: "sub9",
      icon: <MdOutlineFeedback />,
      isAdd: true,
      route: routes.ratings,
    },
    {
      title: "Transaction History",
      key: "sub10",
      icon: <AiOutlineTransaction />,
      isAdd: false,
      route: routes.transactionHistory,
    },
    {
      title: "Settings",
      key: "sub11",
      icon: <AiFillSetting />,
      isAdd: false,
      route: routes.settings,
    },
  ];

  const getAllItems = () => {
    return menuOptions.map((item: MenuOptionInterface) => {
      return (
        <Menu.Item
          key={item.key}
          icon={item.icon}
          onClick={() => {
            if (menuState.isMobile) {
              const action: MenuAction = {
                type: MenuActionKind.COLLAPSED_ON,
              };
              dispatch(action);
            }
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <div className="flex justify-between group">
            <Link to={item.route.list!.path}>{item.title}</Link>
            {item.isAdd && item.route && item.route.create && (
              <div className="hidden group-hover:block">
                <Button type="dashed" size="small">
                  <PlusOutlined className="text-white" />
                  <Link to={item.route.create.path} />
                </Button>
              </div>
            )}
          </div>
        </Menu.Item>
      );
    });
  };

  return (
    <>
      <Sider
        className="fixed h-full shadow-md z-50"
        collapsible={false}
        collapsed={menuState.collapsed}
        width={menuState.fullWidth}
        collapsedWidth={menuState.collapsedWidth}
        theme="light"
        onCollapse={(collapsed) => {
          const action: MenuAction = {
            type: collapsed
              ? MenuActionKind.COLLAPSED_ON
              : MenuActionKind.COLLAPSED_OFF,
          };
          dispatch(action);
        }}
        children={
          <div className="h-full">
            <div className="h-full overflow-auto bg-sidebar pt-5 ps-3 pe-3">
              <Menu
                selectedKeys={selectedKeys}
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                className="border-0 text-grey font-semibold primary bg-sidebar"
              >
                {getAllItems()}
              </Menu>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Sidebar;
